<template>
  <b-row>
    <b-col
      v-if="self.id !== viewingClassesOfId"
      md="12"
    >
      <div class="pb-1">
        <b-button
          variant="flat-secondary"
          class="btn-icon"
          @click="GO_BACK()"
        >
          <feather-icon
            size="20"
            icon="ArrowLeftIcon"
          />
        </b-button>
        <span>
          {{
            `${$t("class-module.classes")} ${$t("messages.of")} ${
              viewingClassesOf.firstname
            } ${viewingClassesOf.lastname}`
          }}
        </span>
      </div>
    </b-col>
    <b-col
      lg="12"
      md="12"
    >
      <b-row>
        <!-- filters and create new button -->
        <b-col
          md="12"
          class="md-justify-content-between"
        >
          <b-row class="p-1 align-items-center">
            <b-col
              v-if="viewingClassesOfId == self.id && !isAParent && !hideCreateButton"
              :md="isATeacher ? '4' : '2'"
              class="justify-content-between"
              sm="12"
            >
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :disabled="isProcessing"
                variant="primary"
                class="btn-pink m-md"
                @click="openCreateClassModal"
              >
                {{ $t("class-module.create-class") }}
              </b-button>
            </b-col>

            <!-- Filter by teachers -->
            <b-col
              v-if="!isAStudent"
              md="12"
              :lg="getInputWidth(3)"
              sm="12"
              class="teacher-filter px-0"
            >
              <div class="d-flex justify-content-between">
                <b-overlay
                  :show="schoolProcessing"
                  class="w-100"
                >
                  <b-form-group>
                    <v-select
                      v-model="school_id"
                      :placeholder="$t('class-module.choose-school')"
                      :clearable="false"
                      label="fullname"
                      :reduce="(school) => school.id"
                      :options="schoolsLists"
                      @input="onSchoolChange"
                    />
                  </b-form-group>
                </b-overlay>

                <b-form-group v-if="!isATeacher"
                              class="w-100 ml-1"
                >
                  <v-select
                    v-model="filterTeacher"
                    :placeholder="$t('class-module.choose-teacher')"
                    :clearable="false"
                    label="fullname"
                    :reduce="(school) => school.id"
                    :options="teachersForFilter"
                    @input="onTeacherChange"
                  >
                    <template #no-options>
                      <template v-if="school_id">
                        {{ $t('class-module.no-teachers-found') }}
                      </template>
                      <em
                        v-else
                        style="opacity: 0.5"
                      >{{ $t('class-module.choose-school-first') }}</em>
                    </template>
                  </v-select>
                </b-form-group>
              </div>
            </b-col>

            <b-col
              md="12"
              :lg="getInputWidth(3)"
              sm="12"
            >
              <b-form-group>
                <!-- <b-form-input
                      v-model="searchTermForClasses"
                      :placeholder="$t('search')"
                      type="text"
                      class="d-inline-block"
                      :disabled="isProcessing"
                    />
                  </b-form-group> -->

                <v-select
                  v-model="searchTermForClasses"
                  :options="searchRooms"
                  :filterable="false"
                  :reduce="(room) => room.id"
                  :disabled="isProcessing"
                  :placeholder="$t('class-module.search-classroom')"
                  class="m-md"
                  label="name"
                  @search="searchClass"
                />
              </b-form-group>
            </b-col>

            <b-col
              md="12"
              :lg="getInputWidth(2)"
              sm="12"
            >
              <b-form-group>
                <v-select
                  v-model="selectedClassType"
                  :options="filteredClassTypes"
                  :reduce="(title) => title.code"
                  :placeholder="$t('class-module.select-class-type')"
                  label="title"
                  class="m-md"
                  :clearable="false"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <!-- filters and create new button end -->

        <b-col
          md="12"
          lg="6"
          sm="12"
        />

        <!-- list of users table -->
        <b-col md="12 mt-2">
          <component
            :is="'class-table'"
            :is-processing="isProcessing"
            :classes="filteredClass.data ? filteredClass : classRoomData"
            :search="searchTermForClasses"
            :selected-class-type="selectedClassType"
            :school="getSchoolById"
            @onClassSelected="onClassRowClick"
            @onClassEdit="editClass"
            @onShowDetails="showClassStats"
            @onClassDelete="showDeleteModalForClass"
            @onEditLink="editClassLink"
            @onImportStudent="value => classIdToImportStudent = value"
            @onShowAllCoursesOf="showAllCoursesOf"
            @onOpenCalendar="viewClassCalendar"
            @addStudent="showAddStudentModal"
            @reloadClassRoom="getClasses"
          />
          <!-- list of users table -->

          <b-col
            v-if="
              ((school_id || filterTeacher) && filteredClass.total) ||
                ((school_id && !filterTeacher) && classRoomData.total) ||
                (!school_id && !filterTeacher && classRoomData.total)
            "
            md="12"
          >
            <b-pagination
              ref="pagination"
              v-model="page"
              hide-goto-end-buttons
              :total-rows="
                paginationCount
              "
              :disabled="isProcessing"
              :per-page="limit"
              :next-text="$t('next')"
              :prev-text="$t('previous')"
              class="custom-pagination"
            />
          </b-col>
        </b-col>
      </b-row>
    </b-col>
    <b-col
      lg="4"
      md="12"
    >
      <b-row>
        <!-- filters and create new button -->
        <b-col
          md="12"
          class="md-d-flex md-justify-content-between"
        >
          <b-row class="p-1">
            <b-col
              md="6"
              sm="12"
            >
              <!-- <b-row class="d-flex justify-content-start p-0">
                <b-col class="p-0">
                  <b-form-group>
                    <b-form-input
                      v-model="searchTermForStudents"
                      :placeholder="$t('search')"
                      type="text"
                      class="d-inline-block"
                      :disabled="isProcessing"
                    />
                  </b-form-group>
                </b-col>
              </b-row> -->
            </b-col>
            <b-col
              md="6"
              sm="12"
            >
              <b-row
                v-if="
                  viewingStudentsForClass !== null &&
                    self.id == viewingStudentsForClass.created_by
                "
                class="md-d-flex justify-content-end"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :disabled="isProcessing"
                  variant="outline-primary"
                  @click="showAddStudentModal"
                >
                  {{ $t("class-module.add-students") }}
                </b-button>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <!-- filters and create new button end -->

        <!-- list of users table -->
        <!-- <b-col md="12 mt-2">
          <b-overlay
            id="overlay-background"
            :show="isProcessing"
            variant="transparent"
            opacity="0.8"
            blur="0.8"
            rounded="sm"
          >
            <student-table
              :is-processing="isProcessing"
              :students="studentsFromSelectedClass"
              :active-class="viewingStudentsForClass"
              :search="searchTermForStudents"
              @onStudentDelete="showDeleteModalForStudent"
              @onViewRubrics="showClassRubrics=true"
              @onViewStudentRubric="(data)=>viewingRubricsOfStudent=data"
              @onViewRubricScore="showStudentRubricScore"
            />
          </b-overlay> -->
        <!-- list of users table -->
        <!-- </b-col> -->
      </b-row>
    </b-col>

    <delete-modal
      name="modal-delete-class"
      :on-delete="deleteClass"
      :title="
        deleteType === 'archive'
          ? $t('class-module.archive-class-title')
          : $t('class-module.delete-class-title')
      "
      :sub-title="
        deleteType === 'archive'
          ? $t('class-module.archive-class-question')
          : $t('class-module.delete-class-question')
      "
      :is-processing="isProcessing"
      :show="idOfClassBeingDeleted != null"
      @close="idOfClassBeingDeleted = null"
    />

    <create-edit-class
      :teachers="teachers"
      :students="students"
      :edit-class="editingClass"
      :show="showClassModal"
      :mode="isEditingClass ? 'edit' : 'create'"
      :schools-lists="schoolsLists"
      :fetching-student="fetchingStudent"
      @close="showClassModal = false"
      @getStudents="getStudentsOfSchool"
      @onClassCreated="getClasses(null, false)"
      @onClassUpdated="getClasses(null, false)"
      @getSchools="getSchoolUsers"
    />
    <live-class-link
      :show="showClassLinkModal"
      :class-room="
        indexOfClassLinkBeingEdited != null
          ? filteredClass.data
            ? filteredClass.data[indexOfClassLinkBeingEdited]
            : classRoomData.data[indexOfClassLinkBeingEdited]
          : null
      "
      @close="showClassLinkModal = false"
      @onClassUpdated="getClasses(null, false)"
    />

    <add-students
      :students="students.data"
      :last-page="students.last_page || 1"
      :class-id="viewingStudentsForClass ? viewingStudentsForClass.id : null"
      :is-high-user="isHighLevel"
      :processing="fetchingStudent"
      @addStudent="getStudentsForClass"
      @fetchMoreStudents="fetchMoreStudents"
      @searchStudents="searchStudents"
      @onStudentsAdded="getClasses(null, false)"
      @close="searchStudent = ''"
    />

    <!-- <class-rubric-modal
      :show="showClassRubrics"
      :classe="viewingStudentsForClass"
      :rubrics="activeClassRubrics"
      :teacher-rubrics="teacherRubrics"
      @close="showClassRubrics=false"
      @refreshRubrics="getClassRubrics"
    /> -->

    <b-modal
      id="all-class-courses"
      ref="all-class-courses"
      v-model="showAllCoursesModal"
      ok-only
      ok-variant="primary"
      :ok-title="$t('actions.close')"
      modal-class="modal-primary"
      centered
      :title="`${$t('class-module.courses-of')} ${
        viewingAllCoursesOf ? viewingAllCoursesOf.name : ''
      }`"
    >
      <div v-if="viewingAllCoursesOf">
        <div
          v-for="course in viewingAllCoursesOf.courses"
          :key="course.name"
          class="d-inline-block ml-1 mb-1"
        >
          <b-button
            variant="primary"
            @click="viewLessons(course.id)"
          >
            {{ course.name }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <excel-upload v-if="classIdToImportStudent"
                  :class-id="classIdToImportStudent"
                  @close="classIdToImportStudent = null"
    />
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  // BFormInput,
  BFormGroup,
  BModal,
  BPagination,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import useApollo from '@/@core/graphql/useApollo'
import { getUserData, isHighLevelUser } from '@/auth/utils'
import ClassTable from '@/views/v2/common/class/components/ClassTable.vue'
// import TeacherClassTable from '@/views/v2/common/class/components/TeacherClassTable.vue'
// import StudentTable from '@/views/v2/common/class/components/StudentTable.vue'
import DeleteModal from '@/views/common/components/DeleteModal.vue'
import CreateEditClass from '@/views/common/class/components/CreateEdit.vue'
import AddStudents from '@/views/common/class/components/AddStudents.vue'
import LiveClassLink from '@/views/common/class/components/LiveClassLink.vue'
import {
  USER_TYPE_TEACHER,
  USER_TYPE_PARENT,
  USER_TYPE_STUDENT,
  USER_TYPE_SCHOOL,
  USER_TYPE_SUPER,
} from '@/const/userType'
// import ClassRubricModal from '@/views/common/class/components/rubric/components/ClassRubricModal.vue'
// import StudentRubricModal from '@/views/common/class/components/rubric/components/StudentRubricModal.vue'
import vSelect from 'vue-select'
import { CLASS_TYPES } from '@/const/classType'
import ExcelUpload from '@/views/common/user/components/ExcelUpload.vue'
import { mapGetters } from 'vuex'
import i18n from '@/libs/i18n'

export default {
  components: {
    BRow,
    CreateEditClass,
    AddStudents,
    DeleteModal,
    // BFormInput,
    BCol,
    BFormGroup,
    BOverlay,
    BButton,
    ClassTable,
    // TeacherClassTable,
    // StudentTable,
    LiveClassLink,
    BModal,
    // ClassRubricModal,
    // StudentRubricModal,
    vSelect,
    BPagination,
    ExcelUpload,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showClassModal: false,
      viewingAllCoursesOf: null,
      showAllCoursesModal: false,
      isProcessing: false,
      searchTermForClasses: null,
      searchTermForStudents: '',
      indexOfClassBeingEdited: null,
      classes: [],
      students: { data: [] },
      teachers: [],
      studentsFromSelectedClass: [],
      idOfClassBeingDeleted: null,
      viewingStudentsForClass: null,
      showClassLinkModal: false,
      indexOfClassLinkBeingEdited: null,
      self: getUserData(),
      viewingClassesOfId: getUserData().id,
      viewingClassesOf: getUserData(),
      USER_TYPE_TEACHER,
      teacherRubrics: [],
      searchClassInput: null,
      tabIndex: 0,
      fetchingTimeout: null,
      idOfClassBeingEdited: null,
      classRoomData: [],
      searchRooms: [],
      allClassRoomSearchLists: [],
      classTypes: CLASS_TYPES,
      selectedClassType: 'all',
      page: this.$route.query.page ? Number(this.$route.query.page) : 1,
      limit: 10,
      deleteType: '',
      schoolsLists: [],
      school_id: null,
      schoolProcessing: false,
      teachersForFilter: [],
      filterTeacher: null,
      filteredClass: {},
      isHighLevel: isHighLevelUser(),
      classIdToImportStudent: null,
      pageForStudents: 1,
      fetchingStudent: false,
      searchStudent: '',
    }
  },
  computed: {
    ...mapGetters('appConfig', ['getSchoolSettings', 'getDistrictSettings']),
    isATeacher() {
      return this.self.usertype === USER_TYPE_TEACHER || this.isAParent
    },
    isAStudent() {
      return this.self.usertype === USER_TYPE_STUDENT
    },
    isEditingClass() {
      return this.indexOfClassBeingEdited !== null
    },
    isAParent() {
      return this.self.usertype === USER_TYPE_PARENT
    },
    isASchool() {
      return this.self.usertype === USER_TYPE_SCHOOL
    },
    isASuper() {
      return this.self.usertype === USER_TYPE_SUPER
    },
    editingClass() {
      const classRoom = this.filteredClass.data
        ? this.filteredClass
        : this.classRoomData
      const cls = this.indexOfClassBeingEdited !== null
        ? classRoom.data[this.indexOfClassBeingEdited]
        : []
      const courseId = cls.type === 'default' ? parseInt(cls?.courses[0]?.id, 10) : null
      const courseName = cls.type === 'default' ? cls?.courses[0]?.name : null
      if (this.indexOfClassBeingEdited !== null) {
        return {
          id: cls?.id,
          name: cls?.name,
          course_id: courseId,
          course_name: courseName,
          created_by: parseInt(cls?.owner?.id, 10),
          course: cls?.courses,
          owner: cls?.owner?.fullname,
          teacher_id: cls?.classroom_teachers?.find(teacher => teacher.role === 'teacher'),
          co_teachers: cls?.classroom_teachers?.filter(teacher => teacher.role === 'co-teacher').map(user => Number(user.user.id)),
          teacher_name: cls?.teacher?.fullname,
          type: cls?.type,
          courses: cls?.courses,
          grade_id: cls.grade_id ? Number(cls.grade_id) : null,
          coursesLesson: cls?.coursesLesson,
          isModern: true,
          school_id: cls.school ? cls.school.id : null,
        }
      }
      return {}
    },
    filteredClassTypes() {
      const filteredClassTypes = []
      this.classTypes.forEach(cls => {
        filteredClassTypes.push({
          code: cls,
          title: i18n.tc(`class-module.filter-type.${cls}`),
        })
      })
      return filteredClassTypes
    },
    getSchoolById() {
      if (this.isHighLevel) {
        return this.school_id
          ? this.schoolsLists.filter(e => e.id === this.school_id)[0]?.fullname
          : ''
      }
      if (this.isATeacher) return this.schoolsLists.length === 1 ? this.schoolsLists[0]?.fullname : ''
      if (this.isAStudent && this.classRoomData.data) return this.classRoomData.data[0]?.school?.fullname
      return ''
    },
    paginationCount() {
      if (this.filterTeacher && this.school_id) {
        return this.filteredClass.total
      }
      if (!this.filterTeacher && this.school_id) {
        return this.filteredClass.data ? this.filteredClass.total : this.classRoomData.total
      }
      return this.classRoomData.total
    },
    hideCreateButton() {
      const distCreateClassInfo = this.getDistrictSettings?.find(metaValue => metaValue.key === 'create_class') ?? '0'
      const schoolCreateClassInfo = this.getSchoolSettings?.find(metaValue => metaValue.key === 'create_class') ?? '0'
      if (this.isAStudent && (distCreateClassInfo?.value === '1' || schoolCreateClassInfo?.value === '1')) {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return true
      }
      return false
    },
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(newVal) {
        if ('user_id' in newVal) {
          this.viewingClassesOfId = newVal.user_id
          this.getUserData()
        }
      },
    },
    searchTermForClasses() {
      this.filteredClass = {}
      this.getClasses()
    },
    page() {
      if (this.filterTeacher) this.filterClassroom(this.school_id, this.filterTeacher)
      else if (this.school_id) this.filterClassroom(this.school_id)
      else this.getClasses()
    },
    selectedClassType() {
      this.getClasses()
      this.searchClass('')
    },
    school_id() {
      this.filteredClass = {}
      this.searchTermForClasses = null
      this.filterTeacher = null
    },
    filterTeacher() {
      this.searchTermForClasses = null
    },
  },
  mounted() {
    if (this.$route.query.user_id) {
      this.filterClassroom(null, null, this.$route.query.user_id)
    } else this.getClasses()

    if (!this.isAStudent) this.getSchoolUsers()
    if (!this.isASuper) this.getStudents()
    this.searchClass('')
  },

  methods: {
    getInputWidth(value) {
      if (this.isATeacher) return value
      return this.isHighLevel ? value : '5'
    },
    getStudents() {
      // useApollo.getUsersOfSchool('student', this.self.id).then(response => {
      //   this.students = response.data?.users?.data
      // })
    },
    getSchoolUsers() {
      if (!this.isASuper) this.schoolProcessing = true
      useApollo
        .getSchoolUsers()
        .then(response => {
          const res = response.data?.schools?.data

          this.schoolsLists = res.map(e => ({
            id: parseInt(e.id, 10),
            fullname: e.fullname,
          }))

          if (this.isASchool) {
            this.school_id = this.self.id
            this.onSchoolChange()
          }

          if (this.isHighLevel && this.schoolsLists.length === 1 && !this.$route.query.user_id) {
            this.school_id = this.schoolsLists[0].id
            this.onSchoolChange()
          }
        })
        .finally(() => {
          this.schoolProcessing = false
        })
    },
    showStudentRubricScore(id) {
      this.$router.push({
        name: `${this.self.usertype}-rubrics`,
        params: { id, classId: this.viewingStudentsForClass.id },
      })
    },
    showAllCoursesOf(data) {
      this.viewingAllCoursesOf = data
      this.showAllCoursesModal = true
    },
    viewClassCalendar(id) {
      this.$router.push({
        name: `${this.self.usertype}-class-calendar`,
        params: { id, classId: id },
      })
    },
    viewLessons(id) {
      this.$router.push({
        name: `${this.self.usertype}-lessons`,
        params: { id, classId: this.viewingAllCoursesOf.class_id },
      })
    },
    getUserData() {
      useJwt.getUser(this.viewingClassesOfId).then(res => {
        this.viewingClassesOf = res.data.data
      })
    },
    showAddStudentModal(id) {
      // when student add from school
      this.viewingStudentsForClass = { id }
      this.getStudentsForClass(id)
    },
    getQueryParams() {
      const id = parseInt(this.$route.query.id, 10)
      if (!id) return
      const classIndex = this.classes.findIndex(a => a.id === id)
      if (classIndex === -1) return
      this.editClass(id, classIndex)
    },
    getTeachers() {
      useJwt.getListOfTeachers().then(response => {
        this.teachers = response.data.data ? response.data.data : []
      })
    },

    getStudentsForClass(id) {
      this.fetchingStudent = true

      useJwt.getStudentsForClass(id, { page: this.pageForStudents, limit: 60, search: this.searchStudent }).then(response => {
        if (this.pageForStudents === 1) this.students = response.data.data || []
        else {
          const data = response.data.data.data ? response.data.data.data : []
          this.students.data = [...this.students.data, ...data]
        }
      }).finally(() => {
        this.fetchingStudent = false
      })
    },
    getClasses(type = null, processing = true) {
      if (processing) this.isProcessing = true
      let { selectedClassType } = this
      if (type) {
        selectedClassType = type
        this.selectedClassType = type
      }

      useApollo
        .fetchClassRoomData(
          selectedClassType,
          this.page,
          this.limit,
          this.searchTermForClasses,
        )
        .then(res => {
          this.classRoomData = res.data.rooms
          // if (this.isAStudent && this.classRoomData.total === 1 && !this.searchTermForClasses) {
          //   this.$router.push({
          //     name: 'student-view-students',
          //     params: {
          //       id: this.classRoomData.data[0]?.id,
          //     },
          //   })
          // }
          const queryParam = `page=${this.page}`
          // Get the current URL
          let currentURL = window.location.href

          // Check if the URL already contains a query string
          if (currentURL.includes('?')) {
            currentURL = currentURL.substring(0, currentURL.indexOf('page='))
            currentURL += `&${queryParam}`
          } else {
            currentURL += `?${queryParam}`
          }
          currentURL = currentURL.replace(new RegExp('&', 'g'), '')

          // Replace the current URL without reloading the page
          window.history.replaceState(null, '', currentURL)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    deleteClass(bvModalEvt) {
      bvModalEvt.preventDefault()
      useJwt
        .deleteClass(this.idOfClassBeingDeleted, {
          params: {
            type: this.deleteType,
          },
        })
        .then(response => {
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.isProcessing = false
          this.idOfClassBeingDeleted = null
          this.getClasses()
        })
    },
    onClassRowClick(params) {
      this.viewingStudentsForClass = params
      this.getStudentsOfClass()
    },

    editClass(id, index) {
      if (this.isATeacher && !Object.values(this.teachers).length) this.getTeachers()
      this.showClassModal = true
      this.indexOfClassBeingEdited = parseInt(index, 10)
      this.idOfClassBeingEdited = id
    },
    editClassLink(id, index) {
      this.showClassLinkModal = true
      this.indexOfClassLinkBeingEdited = index
    },
    showDeleteModalForClass(id, type = 'delete') {
      this.deleteType = type
      this.idOfClassBeingDeleted = id
    },
    openCreateClassModal() {
      if (!Object.values(this.students).length && this.isHighLevel) this.getStudentsOfSchool(this.schoolsLists[0].id)
      if (this.isATeacher) {
        if (!Object.values(this.teachers).length) this.getTeachers()
      }

      this.indexOfClassBeingEdited = null
      this.$nextTick(() => {
        this.showClassModal = true
      })
    },
    getStudentsOfSchool(id) {
      this.fetchingStudent = true
      if (Array.isArray(id)) {
        if (id.length === 0) {
          this.students = []
        }
        useApollo.getUsersOfTypes('userType', id).then(response => {
          this.students.data = response.data?.studentsByTeacherGroup?.data
        }).finally(() => {
          this.fetchingStudent = false
        })
        return
      }
      useJwt.getStudentsForClass(id, { page: this.pageForStudents, limit: 60, search: this.searchStudent }).then(response => {
        if (this.pageForStudents === 1) this.students = response.data.data || []
        else {
          const data = response.data.data.data ? response.data.data.data : []
          this.students.data = [...this.students.data, ...data]
        }
      }).finally(() => {
        this.fetchingStudent = false
      })
    },
    showClassStats(id) {
      this.$router.push({
        name: `${this.self.usertype}-class`,
        params: { id },
        query:
          this.self.id === this.viewingClassesOfId
            ? {}
            : { user_id: this.viewingClassesOfId },
      })
    },
    searchClass(value) {
      this.searchClassInput = value
      if (this.fetchingTimeout) clearTimeout(this.fetchingTimeout)
      const { selectedClassType } = this
      const userId = this.viewingClassesOfId
        ? parseInt(this.viewingClassesOfId, 10)
        : this.self.id
      this.fetchingTimeout = setTimeout(() => {
        useApollo
          .searchRooms(value, userId, selectedClassType)
          .then(res => {
            this.searchRooms = res.data.searchRooms
            this.allClassRoomSearchLists = res.data.searchRooms
          })
          .catch(error => {
            this.showErrorMessage(error)
          })
      }, 500)
    },
    titleCase(value) {
      value.toLowerCase().split(' ')
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
    onSchoolChange() {
      this.filterTeacher = null
      // eslint-disable-next-line eqeqeq
      this.searchRooms = this.allClassRoomSearchLists.filter(item => item.school?.id == this.school_id)
      useApollo.getUsersOfSchool('teacher', this.school_id).then(response => {
        this.teachersForFilter = response.data?.users?.data

        this.page = 1
        if (this.teachersForFilter.length === 1) {
          this.filterTeacher = this.teachersForFilter[0].id
          this.onTeacherChange()
        } else if (!this.isASchool) this.filterClassroom(this.school_id)
      })
    },
    onTeacherChange() {
      this.page = 1
      this.filterClassroom(this.school_id, this.filterTeacher)
    },
    // filter classroom by school or teacher or student
    filterClassroom(schoolId = null, teacherId = null, studentId = null) {
      this.isProcessing = true
      const classes = {
        total: 0,
        data: [],
      }
      useApollo
        .filterClassroom(schoolId, teacherId, studentId, this.page, this.limit)
        .then(response => {
          const res = response.data?.rooms
          classes.total = res.total
          classes.data = res.data
          this.filteredClass = classes
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.isProcessing = false
        })
    },

    fetchMoreStudents(page) {
      this.pageForStudents = page
      this.getStudentsForClass(this.viewingStudentsForClass.id)
    },
    searchStudents(input) {
      this.searchStudent = input
      if (input) {
        this.pageForStudents = 1
      }
      this.getStudentsForClass(this.viewingStudentsForClass.id)
    },
  },
}
</script>
<style>
.vs__open-indicator {
  fill: #a1a1a1 !important;
}
.teacher-filter .vs__actions {
  display: none;
}

.teacher-filter .vs--single .vs__dropdown-toggle {
  padding-left: 0 !important;
}
.vs__dropdown-toggle {
  background-color: #fff;
}
</style>
