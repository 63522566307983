<template>
  <div class="class-part">
    <div class="d-flex justify-content-between class-head-title">
      <div>
        <h5 class="p-1 mb-0">
          <b class="text-bold">{{ $t("class-module.list-of-classes") }} </b>
          <span class="ml-1">
            <b-badge
              v-if="!isProcessing"
              pill
              variant="primary"
              class="bg-pink"
            >{{ classes.total }}</b-badge>
          </span>
        </h5>
      </div>
      <div
        v-if="school"
        class="d-flex justify-content-between"
      >
        <h5 class="p-1 mb-0">
          <b class="text-bold">{{ $t("class-module.school") }}</b>: {{ school }}
        </h5>
      </div>
    </div>
    <div>
      <b-row v-if="!isProcessing && classes && classes.data">
        <b-col
          v-for="(cls, index) in classes.data"
          :key="cls.id"
          lg="6"
          md="12"
          xl="6"
        >
          <b-card v-if="cls">
            <b-row
              class="header"
            >
              <b-col
                md="10"
                class="d-flex align-items-baseline"
              >
                <!-- Class Name -->
                <h2
                  v-b-tooltip.hover="cls.name"
                  class="m-0 p-0 class-title"
                >
                  {{ cls.name?.split('#')[0] }}
                </h2>
                <!-- Live Class Link -->
                <b-badge
                  v-if="cls.link"
                  pill
                  class="ml-1"
                  variant="dark"
                >
                  <a
                    target="_"
                    @click.prevent="openClassLink(cls.link, cls.id)"
                  >{{ $t("labels.live-link") }}</a>
                </b-badge>
              </b-col>

              <!-- More Options -->
              <b-col
                v-if="!isAParent"
                md="2"
                class="text-right"
              >
                <b-dropdown
                  v-if="
                    (cls.owner && self.id == cls.owner.id) ||
                      isATeacher || isHighUser || isSchoolUser
                  "
                  id="dropdown-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  right
                  class="pr-0"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-left vertical-icon"
                    />
                  </template>
                  <b-dropdown-item
                    v-if="(isATeacher || isHighUser) && !isAParent"
                    :disabled="isProcessing"
                    @click.stop="$emit('onEditLink', cls.id, index)"
                  >
                    <feather-icon icon="LinkIcon" />
                    {{ $t("actions.edit-link") }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="(cls.owner && self.id == cls.owner.id) || isHighUser || isSchoolUser || isAClassRoomTeacher(cls)"
                    :disabled="isProcessing"
                    @click.stop="$emit('onClassEdit', cls.id, index)"
                  >
                    <feather-icon icon="EditIcon" />
                    {{ $t("actions.edit") }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="isATeacher || isHighUser || isSchoolUser"
                    :disabled="isProcessing"
                    @click.stop="$emit('onImportStudent', cls.id, index)"
                  >
                    <feather-icon icon="FileTextIcon" />
                    {{ $t('class-module.import-student') }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="(cls.owner && self.id == cls.owner.id) || isHighUser"
                    :disabled="isProcessing"
                    @click.stop="$emit('onClassDelete', cls.id, 'archive')"
                  >
                    <feather-icon icon="ArchiveIcon" />
                    {{ $t("actions.archive") }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="(cls.owner && self.id == cls.owner.id) || isHighUser"
                    :disabled="isProcessing"
                    @click.stop="$emit('onClassDelete', cls.id, 'delete')"
                  >
                    <feather-icon icon="TrashIcon" /> {{ $t("actions.delete") }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
            <hr class="w-100">
            <b-row
              class="body mb-1"
            >
              <b-col
                lg="5"
                xl="4"
                md="4"
                sm="3"
              >
                <img
                  v-if="cls.image"
                  class="w-100 h-100"
                  style="height: 150px; width: 150px"
                  :src="cls.image"
                >
                <avatar
                  :username="cls.name"
                  :rounded="false"
                  color="#fff"
                  :size="notResponsiveWindow ? 180 : 130"
                  class="avatar-image d-sm-block"
                />
              </b-col>
              <b-col
                lg="7"
                md="7"
                sm="9"
                xl="8"
                class="px-2 p-sm-n-2 pl-xl-2 pt-xl-1"
              >
                <b-row
                  v-if="(cls.courses.length > 0) && !hideCourses"
                  class="course-section"
                >
                  <b-col md="12 p-0 m-0">
                    <h5>{{ $t("class-module.course-names") }}:</h5>
                  </b-col>
                  <b-col
                    v-if="cls.courses"
                    class="p-0 m-0"
                  >
                    <span
                      v-for="(course, courseIndex) in cls.courses"
                      :key="courseIndex"
                    >
                      <b-badge
                        v-if="courseIndex < 2"
                        variant="primary"
                        class="cursor-pointer dark-blue-badge m-md"
                        @click="!isAParent && viewLessons(course.id, cls.id)"
                      >
                        {{ course.name }}
                      </b-badge>
                    </span>

                    <b-badge
                      v-if="cls.courses && cls.courses.length > 2"
                      :style="`padding:6px;font-size:10px;`"
                      variant="primary"
                      class="cursor-pointer m-md"
                      @click="
                        $emit('onShowAllCoursesOf', {
                          ...{ class_id: cls.id },
                          ...cls,
                        })
                      "
                    >
                      {{ $t("class-module.view-all") }}
                    </b-badge>
                  </b-col>
                  <!-- <b-col
                    v-else-if="cls.course"
                    class="p-0 m-0"
                  >
                    <span>
                      <b-badge
                        variant="primary"
                        class="cursor-pointer dark-blue-badge"
                        @click="(isAStudent || isATeacher) ? viewLessons(cls.course.id,cls.id):null"
                      >
                        {{ cls.course.name }}
                      </b-badge>
                    </span>
                  </b-col> -->
                </b-row>

                <b-row
                  v-if="filterTeacherByRole(index)"
                  class="teacher-section pt-1"
                >
                  <b-col md="12 p-0 m-0">
                    <h5>
                      {{ $t("actions.teacher") }}:
                      {{ filterTeacherByRole(index) }}
                      <!-- {{
                        cls.teachers[0].id === self.id
                          ? $t("you")
                          : cls.teachers[0].fullname
                      }} -->
                    </h5>
                  </b-col>
                </b-row>
                <b-row
                  v-if="cls.students && !hideNoOfStudent"
                  class="pt-1"
                >
                  <b-col
                    md="12"
                    class="p-0 m-0"
                  >
                    <h5>
                      {{ $t("student-report-module.total-students") }}:
                      {{ cls.students.length }}
                    </h5>

                  </b-col></b-row>
                <b-row class="class-action pt-1">
                  <b-col
                    md="12"
                    class="m-0 p-0 justify-content-between"
                  >
                    <b-button
                      v-if="cls.owner?.id == self.id || isHighUser || isATeacher"
                      v-b-modal.modal-add-students
                      variant="outline-danger outline-pink"
                      :disabled="isProcessing"
                      class="m-md"
                      @click.stop="
                        $emit('addStudent', cls.id)
                      "
                    >
                      <feather-icon icon="PlusIcon" />
                      {{ $t("actions.students") }}
                    </b-button>
                    <b-button
                      variant="outline-danger outline-pink"
                      class="m-md"
                      :disabled="isProcessing"
                      @click="viewStudent(cls)"
                    >
                      <feather-icon icon="EyeIcon" />
                      {{ $t("actions.go-to-classroom") }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-row
                  v-if="isATeacher"
                  class="pt-1"
                >
                  <b-col
                    md="12"
                    class="m-0 p-0"
                  />
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-progress :max="100"
                            height="1.5rem"
                            :precision="2"
                >
                  <b-progress-bar :value="cls.course_completion"
                                  :variant="courseProgress(cls.course_completion)"
                  >
                    <span><strong>{{ cls.course_completion.toFixed(2) }}</strong>% {{ $t('class-module.course-progress') }}</span>
                  </b-progress-bar>
                </b-progress>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col
                sm="8"
                lg="5"
                class="align-items-center text-nowrap"
              >
                <div class="justify-content-between">
                  <div>
                    <h5
                      v-if="cls.owner && !isAStudent"
                      class="d-flex"
                    >
                      {{ $t("class-module.class-table.owner") }}:
                      {{
                        cls.owner.id == self.id ? $t("you") : cls.owner.fullname
                      }}
                    </h5>
                  </div>
                  <div v-if="userLevelChecker(6)">
                    <h5
                      v-if="cls.school"
                      class="d-flex"
                    >
                      {{ $t("class-module.class-table.school") }}:
                      {{ cls.school.fullname }}
                    </h5>
                  </div>
                </div>
              </b-col>
              <b-col
                sm="4"
                lg="7"
                class="text-right"
              >
                <!-- <b-button
                      variant="secondary"
                      class="ml-2 btn-whitish-gray"
                      :disabled="isProcessing"
                      @click.stop="$emit('onShowDetails', cls.id)"
                    >
                      <feather-icon icon="EyeIcon" /> {{ $t("actions.view") }}
                    </b-button> -->
                <b-button
                  v-if="(isATeacher || isHighUser || isAStudent) && !isAParent"
                  :class="{ disabled_container: isArchived }"
                  variant="secondary"
                  class="ml-2 px-1 btn-whitish-gray"
                  :disabled="isProcessing"
                  @click.stop="$emit('onOpenCalendar', cls.id, index)"
                >
                  <feather-icon icon="CalendarIcon" />
                  {{ $t("navigation-menu.calendar") }}
                </b-button>
                <b-button
                  v-if="isArchived && cls.owner.id == self.id"
                  variant="primary"
                  class="ml-2"
                  :disabled="isProcessing"
                  @click.stop="confirmRestore(cls.id)"
                >
                  <feather-icon icon="CornerDownLeftIcon" /> {{ $t("Restore") }}
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row
        v-if="
          classes && !isProcessing && classes.data && classes.data.length === 0
        "
      >
        <b-col
          md="12"
          class="text-center"
        >
          <b-card>
            <span class="h4 text-dark-blue">{{
              $t("messages.no-data-available")
            }}</span>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-if="isProcessing"
          class="text-center"
        >
          <b-card>
            <b-spinner />
          </b-card>
        </b-col>
      </b-row>
    </div>
    <confirm-restore-modal
      :show="restoreParams.show || false"
      varient="primary"
      :is-processing="restoreParams.isProcessing"
      :title="restoreParams.title"
      :sub-title="restoreParams.subTitle"
      :on-delete="handleRestore"
      @close="restoreParams = {}"
    />
    <b-modal
      :visible="!!redirectingLink"
      content-class="shadow"
      :title="$t('class-module.opening')"
      no-fade
      ok-only
      ok-title="Accept"
      hide-footer
      @hide="redirectingLink = null"
      @close="redirectingLink = null"
    >
      <b-overlay
        v-if="!isAttendanceDone"
        show
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        {{ $t('class-module.redirecting-to') }} {{ redirectingLink }}
      </b-overlay>
      <div v-else>
        <a :href="redirectingLink">
          Click here to join the live class if it does not redirect automatically.
        </a>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard,
  BButton,
  BBadge,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BSpinner,
  BModal,
  BOverlay,
  BProgress,
  BProgressBar,
} from 'bootstrap-vue'
import { getUserData, isHighLevelUser } from '@/auth/utils'
import {
  USER_TYPE_TEACHER,
  USER_TYPE_STUDENT,
  USER_TYPE_PARENT,
  USER_TYPE_SCHOOL,
  USER_TYPE_SUPER,
} from '@/const/userType'
import Ripple from 'vue-ripple-directive'
import Avatar from 'vue-avatar'
import {
  CLASS_TYPE_DEFAULT,
  CLASS_TYPE_CUSTOM,
  CLASS_TYPES,
} from '@/const/classType'
import ConfirmRestoreModal from '@/views/common/components/DeleteModal.vue'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import { isVisible } from '@/utils/visibilitySettings'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    BButton,
    BBadge,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    Avatar,
    BSpinner,
    ConfirmRestoreModal,
    BModal,
    BOverlay,
    BProgress,
    BProgressBar,
  },
  directives: {
    Ripple,
  },
  props: {
    classes: {
      type: [Object, Array],
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
    isProcessing: {
      type: Boolean,
      default: false,
    },
    selectedClassType: {
      type: String,
      default: '',
    },
    school: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedClass: null,
      self: getUserData(),
      tabIndex: 0,
      classTypes: CLASS_TYPES,
      classTypeDefault: CLASS_TYPE_DEFAULT,
      classTypeCustom: CLASS_TYPE_CUSTOM,
      restoreParams: {},
      userLevelChecker: isHighLevelUser,
      redirectingLink: null,
      isAttendanceDone: false,
      // hideCourses: true,
    }
  },
  computed: {
    ...mapGetters('appConfig', ['getSchoolSettings', 'getDistrictSettings']),
    isHighUser() {
      return isHighLevelUser()
    },
    isAStudent() {
      return this.self.usertype === USER_TYPE_STUDENT
    },
    isAParent() {
      return this.self.usertype === USER_TYPE_PARENT
    },
    isATeacher() {
      // here parent is also like teacher user who can only their child user report
      return this.self.usertype === USER_TYPE_TEACHER || this.self.usertype === USER_TYPE_PARENT
    },
    isArchived() {
      return this.selectedClassType === 'archived'
    },
    isSchoolUser() {
      return this.self.usertype === USER_TYPE_SCHOOL
    },
    isSuperAdmin() {
      return this.self.usertype === USER_TYPE_SUPER
    },
    notResponsiveWindow() {
      return window.innerWidth >= 1650
    },
    schoolName() {
      return this.classes?.data?.[0]?.school?.fullname
    },
    noOfStudentSettings() {
      return {
        distNoOfStudentInfo: this.getDistrictSettings?.find(metaValue => metaValue.key === 'show_no_of_students')?.value ?? '0',
        schoolNoOfStudentInfo: this.getSchoolSettings?.find(metaValue => metaValue.key === 'show_no_of_students')?.value ?? '0',
      }
    },
    hideNoOfStudent() {
      return this.isAStudent && (this.noOfStudentSettings?.distNoOfStudentInfo === '1' || this.noOfStudentSettings?.schoolNoOfStudentInfo === '1')
    },
    hideCourses() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      return !isVisible('class_card_courses', schoolSetting, distSetting)
    },
  },
  watch: {
    tabIndex(value) {
      this.$emit('onTabChanged', value)
    },
  },
  mounted() {
    // isVisible('class_card_courses', { fetch: this.$store.state.appConfig.justLoggedIn }).then(response => {
    //   // this.hideCourses = !response
    //   this.$store.commit('appConfig/UPDATE_JUST_LOGGED_IN', false)
    // })
  },
  methods: {
    isAClassRoomTeacher(item) {
      const authUserId = this.AUTH_USER().id
      // eslint-disable-next-line eqeqeq
      return !!item.classroom_teachers.find(obj => obj.user?.id == authUserId)
    },
    viewLessons(id, classId) {
      this.$router.push({
        name: `${this.self.usertype}-lessons`,
        params: { id, classId },
      })
    },
    activeClass(item, type) {
      if (!this.selectedClass) return ''
      if (!item || type !== 'row') return ''
      if (item.id === this.selectedClass.id) return 'active-column'
      return ''
    },
    onClassRowClick(params) {
      this.selectedClass = params
      this.$emit('onClassSelected', params)
    },
    confirmRestore(id) {
      this.restoreParams = {
        id,
        title: 'Restore Classroom',
        show: true,
        isProcessing: false,
        subTitle: 'Are you sure you want to restore classroom?',
        varient: 'primary',
      }
    },
    handleRestore() {
      const { id } = this.restoreParams
      this.restoreParams = { ...this.restoreParams, isProcessing: true }
      useJwt
        .retoreClassroom(id)
        .then(response => {
          this.showSuccessMessage(response)
          this.$emit('reloadClassRoom', 'archived')
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.restoreParams = {}
        })
    },
    viewStudent(cls) {
      this.$router.push({
        name: `${this.self.usertype}-view-students`,
        params: { id: cls.id },
      })
    },
    openSSOLink(link) {
      this.isAttendanceDone = false
      const ssoProvider = link.split(process.env.VUE_APP_SSO_PREFIX)[1]
      useJwt.getSsoLink(ssoProvider).then(response => {
        window.open(response.data.data, '_self')
        setTimeout(() => {
          this.isAttendanceDone = true
        }, 1500)

        // this.redirectingLink = null
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    openClassLink(link, classId) {
      this.redirectingLink = link
      this.isAttendanceDone = false
      useJwt.updateStudentAttendanceWithLiveLink({
        classroom_id: classId,
      }).then(response => {
        this.showSuccessMessage(response)
        if (link.startsWith(process.env.VUE_APP_SSO_PREFIX)) {
          this.openSSOLink(link)
        } else {
          window.open(link, '_self')
          setTimeout(() => {
            this.isAttendanceDone = true
          }, 1500)
          // this.redirectingLink = null
        }
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    filterTeacherByRole(index) {
      /* eslint-disable prefer-destructuring */
      const teachers = this.classes?.data?.[index].classroom_teachers
      const teacher = teachers.find(user => user.role === 'teacher')

      if (Number(teacher?.user?.id) === this.self.id) return i18n.tc('you')
      return teacher?.user?.fullname
    },
    courseProgress(value) {
      if (value >= 25.0 && value <= 50.0) { return 'warning' }
      if (value > 50 && value <= 75.0) {
        return 'primary'
      }
      if (value > 75.0) {
        return 'success'
      }
      return 'danger'
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.class-table .active-column {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

.class-table tr {
  cursor: pointer;
}
.class-table {
  margin-bottom: 0;
}

.disabled_container {
  pointer-events: none !important;
}
.class-head-title {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 5px;
}
.dark-layout {
  .class-head-title {
    background-color: $theme-dark-card-bg !important;
    background-color: $theme-dark-box-shadow;
  }
 }
</style>
